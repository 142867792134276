<template>
  <a :href="getPriceOnRequestUrl" class="price-onreq-btn">
    <i class="fa fa-file-text-o"></i>
    <span v-translation="{ code: 'button_price_req', type: 'button' }"></span>  
  </a>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    productId: { type: String, required: false },
    productDescription: { type: String, required: false }
  },
  computed: {
    ...mapGetters(['bespokeGlobalWs']),
    getPriceOnRequestUrl() {
      var url = new URL(`${this.bespokeGlobalWs.priceOnRequestUrl}`, document.location);
      url.searchParams.append('prodCode', this.productId);
      url.searchParams.append('prodDesc', this.productDescription);
      // Add more params if necessar
      // var prodDesc2 = this.product.customStrings.PROD_DESC2;
      // if (prodDesc2 !== undefined) {
      //   url.searchParams.append('prodDesc2', prodDesc2);
      // }
      return url;
    }
  }
}
</script>

<style>

</style>