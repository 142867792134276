<template>
  <div class="cmp-product-list-item utlz-col-sm-4" :class="'utlz-col-lg-' + blockSize">
    <div class="utlz-row utlz-no-gutters utlz-gutters-sm item-wrapper">      
      <div class="utlz-col-3 utlz-col-sm-12 img-container">

          <!-- BESPOKE GOOL-2 -->
          <!-- <utlz-favorites-control v-if="showFavorites" :product="product"></utlz-favorites-control> -->
          <!-- END BESPOKE GOOL-2 -->

          <template
            v-if="product.productImageBanners.length && screenWidth >= 576">
            <div class="product-banners">
              <div v-for="(banner, index) in productImageBanners" :key="'prod-banner-wrapper-' + index" :class="{ [banner.position]: true }" class="prod-banner-wrapper">
                <div v-for="(bannerVal, ind) in banner.values" :key="'prod-banner-wrapper-' + ind">
                  <span class="text-banner" v-if="bannerVal.Type === 0" :style="'background-color:' + bannerVal.Color + ';color: ' + bannerVal.TextColor">
                    {{ bannerVal.Text }}
                  </span>
                  <img class="img-banner" v-if="bannerVal.Type === 1" :src="'/' + bannerVal.Image" />
                </div>
              </div>
            </div>
          </template>

          <figure class="img-square prod-img">
            <!--  -->
            <template v-if="product.prices != 'undefined'">
              <span v-if="isSalesAction" v-translation="{ type: 'label', code: 'label_sales_action' }" class="action-banner"></span>
            </template>
            <!--  -->
            <a :href="product.targetUrl" class="clickthrough">
              <img class="img-fluid" :alt="product.imageAltText" :title="product.imageTitleText" :src="product.imageUrl ? product.imageUrl : '/documents/productimages/not-available-large.jpg'" />
            </a>
          </figure>
      </div>

      <div class="utlz-col-9 utlz-col-sm-12 text-container">
        <a :href="product.targetUrl" class="clickthrough">

          <template v-if="product.productImageBanners.length && screenWidth <= 576">
            <div class="product-banners">
              <div v-for="(banner, index) in productImageBanners" :key="'prod-banner-wrapper-' + index" :class="{ [banner.position]: true }" class="prod-banner-wrapper">
                <div v-for="(bannerVal, ind) in banner.values" :key="'prod-banner-wrapper-' + ind">
                  <span class="text-banner" v-if="bannerVal.Type === 0" :style="'background-color:' + bannerVal.Color + ';color: ' + bannerVal.TextColor">
                    {{ bannerVal.Text }}
                  </span>
                  <img class="img-banner" v-if="bannerVal.Type === 1" :src="'/' + bannerVal.Image" />
                </div>
              </div>
            </div>
          </template>

          <div class="product-code">
            <span class="value">{{ product.id }}</span>
          </div>
          <h2 class="prod-title header-small header-normal-sm">{{ product.shortDescription }}</h2>
        </a>

        <!-- BESPOKE GOOL: Added showExclVatLabel=true and :productCode="product.id"-->
        <div class="ph-product-price" v-if="product.prices.length">
          <utlz-product-price
            :showExclVatLabel="true"
            :prices="product.prices[0]"
            :parentClassRef="'ref-product-list-item'"
            :productCode="product.id"
            @allPricesRetrieved="retrievedAllPrices($event)"
          ></utlz-product-price>
        </div>

        <template v-if="product.prices.length && product.prices[0].rawPrice == 0 && allPricesRetrieved">
          <utlz-price-onrequest :productDescription="product.shortDescription" :productId="product.id"></utlz-price-onrequest>
        </template>
        <!-- END BESPOKE GOOL -->

        <!-- BESPOKE GOOL-6: Set product variant dropdown to false -->
        <!-- <utlz-product-variant
          v-if="false"
          :product="computedProduct"
          :productVariants="product.productVariants"
          @selectedProduct="selectedProduct($event)"
        ></utlz-product-variant> -->
        <!-- END BESPOKE GOOL-6 -->

        
        <div class="ph-order-product" v-if="allPricesRetrieved && product.prices.length && product.prices[0].rawPrice != 0">
          <transition name="fade">
            <!-- BESPOKE GOOL-2: Show button to click through to product details instead of order product -->
            <a :href="product.targetUrl" class="order-button button theme-primary">
              <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              <span class="order-button-plus">+</span>
            </a>
            <!-- END BESPOKE GOOL-2 -->
            <!-- <utlz-order-product
              v-if="showOrderProductControl && product.prices.length"
              :product="computedProduct"
              :parentClassRef="'ref-product-list-item'"
            >
            </utlz-order-product> -->
          </transition>

          <transition name="fade">
            <p v-if="showOutOfStockMessage && showStock" class="out-of-stock-msg">
              <i class="fa fa-warning"></i>
              <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
            </p>
          </transition>
        </div>

        <!-- If product has variants, disable stock and
        use stock in OrderProduct.vue component which is variant specific -->

        <!-- BESPOKE GOOL-2: Disabled stock -->
        <!-- <utlz-product-stock
          v-if="!product.variantKey || groupProductsList == 0"
          :stock="product.stock"
          :stockTotal="product.stock"
        ></utlz-product-stock> -->
        <!-- END BESPOKE GOOL-2 -->

      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Product } from 'src/models/product.model';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductVariant from 'src/components/webshop/products/product-variant/ProductVariant.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
// BESPOKE GOOL-67
import PriceOnRequest from './../../../price-on-request/PriceOnRequest.vue';
// END BESPOKE GOOL-7
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-product-variant': ProductVariant,
    'utlz-favorites-control': FavoritesControl,
    // BESPOKE GOOL-67
    'utlz-price-onrequest': PriceOnRequest
    // END BESPOKE GOOL-67
  },
  props: {
    product: { type: Product, required: true },
    blockSize: { type: Number, required: false, default: 3 }
  },
  data () {
    return {
      activeProductVariantId: null,
      // BESPOKE
      allPricesRetrieved: false
      // END BESPOKE
    };
  },
  computed: {
    ...mapState('elastic', ['layoutType']),
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'groupProductsList']),
    isSalesAction () { 
      return this.product.prices.length > 0 ? this.product.prices[0].isSalesAction : false;
    },
    showOrderProductControl () {
      if (!this.showPrices) {
        return false;
      } else if (this.showOrderProduct) {
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.product.stock !== null) {
          return this.stockLimit && this.product.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showOutOfStockMessage () {
      if (!this.stockLimit) {
        return false;
      } else if (this.product.stock !== null && this.showOrderProduct) {
        return this.product.stock.stockTotal <= 0;
      } else {
        return false;
      }
    },
    computedProduct () {
      if (!this.product.variantKey) {
        return this.product;
      } else {
        if (this.activeProductVariantId) {
          const index = this.product.productVariants.findIndex(product => {
            return product.id === this.activeProductVariantId;
          });
          return this.product.productVariants[index];
        } else {
          return this.product;
        }
      }
    },
    productImageBanners () {
      return this.product.productImageBanners.filter(banner => banner.show === true);
    }
  },  
  methods: {
    selectedProduct (id) {
      this.activeProductVariantId = id;
    },
    // BESPOKE VLINT-67
    retrievedAllPrices() {
      this.allPricesRetrieved = true;
    }
    // END BESPOKE VLINT-67
  }
};
</script>

<style>
</style>
