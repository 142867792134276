<template>
  <div class="cmp-product-price" v-if="showPrices" ref="priceElement" :class="parentClassRef">
    <ul v-if="retrievedMinPrice && prices.rawPrice != 0" class="price-list flex-row-nowrap align-items-baseline">
      <!-- BESPOKE GOOL: If product has  -->
      <li class="ex-vat-label price-starting-label">
        <span>{{description}}</span>
      </li>
      <li class="default">
        <span>{{ prices.price }}</span>
      </li>
      <li v-if="prices.isSalesAction" class="original salesaction">
        <span>{{ prices.basePrice }}</span>
      </li>
      <!-- BESPOKE GOOL: Added excl VAT label -->
      <li v-if="showExclVatLabel" class="ex-vat-label">
        <span v-translation="{ code: 'label_price1_excl_vat', type: 'label' }"></span>
      </li>      
      <!-- END BESPOKE GOOL -->
    </ul>
    <span v-else-if="!retrievedMinPrice" class="loader"></span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
  props: {
    prices: { type: Object, required: false },
    parentClassRef: { type: String, default: '', required: false },
    // BESPOKE
    productDetails: { type: Boolean, required: false, default: false },
    showExclVatLabel: { type: Boolean, required: false, default: false },
    productCode: {type: String, required: true, default: ''},
    scrollEvent: '',
    isUmbrellaProduct: false
    // END BESPOKE
  },
  computed: {
    ...mapGetters(['showPrices', 'language', 'bespokeEndpoints']),
  },
  data () {
    return {
      description: '',
      initiatedMinPrice: false,
      retrievedMinPrice: false
    }
  },
  watch: {
    isUmbrellaProduct (val) {
      if (val) {
        this.GetMinPrice();
      }
    }
  },
  // END BESPOKE GOOL
  methods: {
    // BESPOKE GOOL-8
    GetMinPrice () {
      axios.post(this.bespokeEndpoints.priceEndpoint, { productCode: this.productCode, language: this.language })
        .then(res => {
            if (res.data.d !== null && res.data.d !== undefined) {
              var price = res.data.d.price;
              this.prices.rawPrice = parseFloat(price.replace('€', '').replace(',', '.'));;
              this.prices.price = price;
              this.description = res.data.d.description;
            }

            this.retrievedMinPrice = true;
            this.$emit('allPricesRetrieved', true);
        });
    },
    handlePrice(){ 
      // On the product details page we do not display prices as "Vanaf" is the product is grouped
      // and acts as an umbrella object so we do not have to retrieve a MinPrice
      if (!this.isUmbrellaProduct && this.productDetails) {
        this.retrievedMinPrice = true;
        this.$emit('allPricesRetrieved', true);
        return;
      }
      // BESPOKE GOOL-45
      // Made getMinPrice lazy loaded, minPrice is not retrieved until it is in the view
      if (!this.initiatedMinPrice && this.$refs.priceElement != undefined) {
        // Price component has been loaded into DOM, determine if cmoponent is is view,
        // or if it should be loaded once scrolled into view
        if (this.$refs.priceElement.getBoundingClientRect().top < window.innerHeight) {
          this.initiatedMinPrice = true;  
          this.GetMinPrice();
        } else {
        document.addEventListener('scroll', ()=> {
          if (this.$refs.priceElement.getBoundingClientRect().top < window.innerHeight && !this.initiatedMinPrice) {    
            this.initiatedMinPrice = true;  
            this.GetMinPrice();
          } 
        });
        }
      }      
    }   
  },
  mounted() {
    if (!this.retrievedMinPrice) {
      this.handlePrice();
    }
  },
  updated () {
    if (!this.retrievedMinPrice) {
     this.handlePrice();
    }
  }
}
</script>

<style>

</style>
