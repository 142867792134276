<template>
  <div class="cmp-product-group">
      <div class="property" v-for="(property, index) in properties" v-bind:key="property">
        <!-- BESPOKE GOOL-45: Wrapped it in showGroupValue -->
        <template v-if="showGroupValue(index)">
          <span class="select-label">{{ property }}</span>
            <select :name="property.replace(' ', '-')" :id="'select-' + property.replace(' ', '-')" v-model="mainModel[property]" @change="onChange($event)">
                <option v-for="variant in getPropertyValues(property)" :key="variant.key" :value="variant.key">
                    {{ variant.value }}
                </option>
            </select>
        </template>
        <!-- END BESPOKE GOOL-45 -->
      </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  props: {
    variants: { type: Array, required: true }
  },
  data () {
    return {
      mainModel: {}
    }
  },
  watch: {
    productDetails (n, o) {
      this.mainModel = this.GetMainModel(n)
    },
    variants (n, o) {
      this.mainModel = this.GetMainModel(this.productDetails)
    }
  },
  computed: {
    ...mapState('productDetails', ['productDetails']),
    // BESPOKE GOOL-45
    // Added umbrella product logic
    ...mapGetters('productDetails', ['isUmbrellaProduct']),
    // END BESPOKE GOOL-45
    properties () {
      if (this.variants.length > 0) {
        return Object.keys(this.variants[0].properties);
      }
      return [];
    },
    mainProductProperties () {
      if (this.variants.length > 0) {
        let variant = this.variants.find((x) => x.id === this.productDetails.id);
        if (variant) { return variant.properties; }
      }
      return [];
    }
  },
  methods: {
    ...mapActions('productDetails', ['updateShownProduct']),
    onChange (event) {
      // BESPOKE GOOL-45
      // Added if statement to filter out empty values
      if (event.target.value) {
        this.updateShownProduct(event.target.value);
        this.$emit('changedGrouping', true);
      }
      // END BESPOKE GOOL-45
    },
    GetMainModel (n) {
      var obj = {}

      if (n !== undefined) { Object.keys(this.mainProductProperties).forEach((x) => obj[x] = n.id); }
      return obj;
    },
    // BESPOKE GOOL-45
    showGroupValue (index) {
      if (this.isUmbrellaProduct) {
        return index === 0;
      } else {
        return true;
      }
    },
    // END BESPOKE GOOL-45
    getPropertyValues (property) {
      let returnList = [];
      let products = this.variants;
      let ogProperties = this.mainProductProperties;
      let mainProductId = this.productDetails.id;

      let propertyIndex = this.properties.indexOf(property)
      if (propertyIndex > 0) {
        let filterValues = []
        for (let i = 0; i < propertyIndex; i++) {
          let propertyName = this.properties[i];

          filterValues.push({ key: propertyName, value: ogProperties[propertyName] });
        }

        filterValues.forEach(function (x) {
          products = products.filter(function (y) {
            return y.properties[x.key] === x.value;
          })
        });
      }

      products.forEach(function (x) {
        if (x.properties[property] !== ogProperties[property] || x.id == mainProductId) {
          // BESPOKE GOOL-45
          // If product grouping is undefined, it belongs to the umbrella product, this should be the first value in the array
          if (x.properties[property] !== '') {
            returnList.push({ key: x.id, value: x.properties[property] })
          }
          // END BESPOKE GOOL-45
        }
      });
      // BESPOKE GOOL
      // Simplified sorting of filters as it was not sorting corectly in the first dropdown
      // and used parseFloat instead of parseInt
      return returnList.filter((value, index, self) => self.findIndex((x) => x.value === value.value) === index)
        .sort((a, b) => parseFloat(a.value.replace(",", ".")) - parseFloat(b.value.replace(",", ".")))
        // .sort(function (x, y) {
        //   const valA = x.value.toUpperCase();
        //   const valB = y.value.toUpperCase();
        //   if (valA > valB) {
        //     comparison = 1;
        //   } else if (valA < valB) {
        //     comparison = -1;
        //   }
        //   return comparison;
        // });
        // END BESPOKE GOOL
    }
  }
}
</script>

<style>

</style>
