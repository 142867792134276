<template>
  <div class="cmp-product-carousel utlz-reset utlz-row blocks" :class="{ 'carousel-enabled': useCarousel }">
    <div class="utlz-col-12">
      <!-- BESPOKE EDEX-40: Added v-if="title.length" -->
      <h2 v-if="title.length">{{ title }}</h2>
      <!-- END BESPOKE EDEX-40 -->
    </div>
    <div class="utlz-col-12">
      <transition-group name="fade" tag="div" :class="'scroll-container-' + scrollContainerId" class="scroll-container list-items-wrapper utlz-row">
        <!-- BESPOKE GOOL: Added index to key since sometimes add double products -->
        <utlz-product-list-item
          @navigateToProductPage="navigateToProductPage($event)"
          v-for="(product, index) in products"
          :key="product.id +'-' + index"
          :product="product"
          :blockSize="blockSize"
          class="product-list-item">
        </utlz-product-list-item>
        <!-- END BESPOKE GOOL -->
      </transition-group>
    </div>
    <template v-if="useCarousel">
      <div class="nav-controls">
        <transition name="fade">
          <div @click="scroll('left')" v-if="showControlLeft" class="nav-control left text-link"><i aria-hidden="true" class="fa fa-chevron-left"></i></div>
        </transition>
        <transition name="fade">
          <div @click="scroll('right')" v-if="showControlRight" class="nav-control right text-link">
            <i aria-hidden="true" class="fa fa-chevron-right"></i></div>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductListItem from 'src/components/webshop/products/product-list-item/ProductListItem.vue';
export default {
  components: {
    'utlz-product-list-item': ProductListItem
  },
  props: {
    title: { type: String, required: false, default: '' },
    blockSize: { type: Number, required: false, default: 3 },
    products: { type: Array, required: true }
  },
  data () {
    return {
      showControlLeft: false,
      showControlRight: true,
      scrollContainerId: ""
    }
  },
  computed: {
    ...mapGetters(['screenWidth']),
    useCarousel () {
      if (this.screenWidth < 576) {
        return this.products.length > 1;
      } else if (this.screenWidth < 992) {
        return this.products.length > 3;
      } else {
        return this.products.length > (12 / this.blockSize);
      }
    }
  },
  methods: {
    scroll (direction) {
      const scrollContainer = document.querySelector('.scroll-container-' + this.scrollContainerId);
      if (direction === 'left') {
        scrollContainer.scrollBy({
          left: -150,
          behavior: 'smooth'
        })
      } else if (direction === 'right') {
        scrollContainer.scrollBy({
          left: scrollContainer.scrollLeft + 150,
          behavior: 'smooth'
        })
      }
      setTimeout(() => {
        this.showControlLeft = scrollContainer.scrollLeft > 1;
        let showRightControlDifference = scrollContainer.scrollWidth - (scrollContainer.offsetWidth + Math.floor(scrollContainer.scrollLeft));                
        this.showControlRight = showRightControlDifference > 1;
      }, 350)
    }
  },
  created () {
    this.scrollContainerId = "-"+((new Date).getTime().toString(16)+Math.floor(1E7*Math.random()).toString(16));
  }
}
</script>

<style>

</style>
