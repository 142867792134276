<template>
  <div class="cmp-elastic-product-details utlz-reset utlz-row">
      <!-- BESPOKE GOOL: Changed class to col-md-5 -->
      <div class="utlz-col-md-5 column-left">
        <utlz-gallery :product="productDetails"></utlz-gallery>
      </div>
      <!-- BESPOKE GOOL: Changed class to col-md-7 -->
      <div class="utlz-col-md-7 column-right product-details-column">
          <div class="product-information">
            <div class="product-code">
                 <!-- BESPOKE GOOL: Added sale banner -->
                <template v-if="productDetails.prices[0] !== undefined">
                  <span v-if="productDetails.prices[0].isSalesAction" v-translation="{ type: 'label', code: 'label_sales_action' }" class="action-banner"></span>
                </template>
                <!-- END BESPOKE GOOL -->

                <!-- BESPOKE GOOL-45: Added prod code label -->
                <span v-translation="{ type: 'label', code: 'label_product_code' }"></span>:
                <span>{{productDetails.id}}</span>
                <!-- END BESPOKE GOOL-45 -->
            </div>            

            <h1>{{productDetails.shortDescription}}</h1> 

            <!-- BESPOKE GOOL-45: Added prod-desc-2 -->
            <div class="prod-desc-2">
              {{ productDetails.subTitle }}
            </div>
            <!-- END BESPOKE GOOL-45 -->

            <!-- BESPOKE GOOL: Only show prices if rawPrice is not 0, because we show price on request -->
            <div class="ph-product-price" v-if="productDetails.prices.length">
                <!-- BESPOKE GOOL: Added showExclVatLabel=true, productCode and productDetails="true" -->
                <utlz-product-price
                    :isUmbrellaProduct="isUmbrellaProduct"
                    :productCode="productDetails.id"
                    :prices="productDetails.prices[0]"
                    :showExclVatLabel="true"
                    :productDetails="true" 
                    @allPricesRetrieved="retrievedAllPrices($event)"
                ></utlz-product-price>
                <!-- END BESPOKE GOOL -->
            </div>            

            <!-- BESPOKE GOOL: Add price on request -->
            <template v-if="productDetails.prices.length && productDetails.prices[0].rawPrice == 0 && allPricesRetrieved">
              <utlz-price-on-request v-if="!isUmbrellaProduct" :productId="productDetails.id" :productDescription="productDetails.shortDescription"></utlz-price-on-request>
            </template>
            <!-- END BESPOKE GOOL-67 -->

            <utlz-group-dropdown @changedGrouping="groupChanged()" :variants="productVariants" />


            <!-- BESPOKE GOOL-67: Show price on request button if price = 0  -->
            <template v-if="productDetails.prices.length && productDetails.prices[0].rawPrice != 0 && allPricesRetrieved">
              <div class="order-product">
                <utlz-order-product
                  v-if="showOrderProductControl && !isUmbrellaProduct"
                  :product="productDetails"
                  :useOrderComment="useOrderLineComments"
                  :useTextButton="true"
                  >
                  <!-- BESPOKE GOOL-45: Added favorites button to act as a slot, and be placed into utlz-order-product and added isUmbrellaProduct-->
                  <div class="favorite">
                    <utlz-favorites-control v-if="showFavorites && !isUmbrellaProduct" :product="productDetails" :useDescription="true"></utlz-favorites-control>
                  </div>
                  </utlz-order-product>
                  <!-- END BESPOKE GOOL-45 -->

                  <transition name="fade">
                    <p v-if="!showOrderProductControl && showStock" class="out-of-stock-msg">
                      <i class="fa fa-warning"></i>
                      <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
                    </p>
                  </transition>
              </div>
            </template>
            <!-- END BESPOKE GOOL-67 -->
            
            
            <!-- BESPOKE GOOL-45: Added favorites button to act as a slot, and be placed into utlz-order-product -->
              <div class="favorite">
                <utlz-favorites-control v-if="showFavorites && isUmbrellaProduct" :product="productDetails" :useDescription="true"></utlz-favorites-control>
              </div>
            <!-- END BESPOKE GOOL-45 -->

            <!-- BESPOKE GOOL-45: Moved stock down -->
            <utlz-product-stock
              :product="productDetails"
              :stock="productDetails.stock"
              :stockTotal="productDetails.stock"
            ></utlz-product-stock>
            <!-- END BESPOKE GOOL-45 -->

            
          </div>
      </div>

      <div class="utlz-col-12 margin-bottom-30">


      <utlz-tabs :tabs="tabs">

        <template v-slot:description>
          <div class="prod-desc-container" :class="{'expandable': useCollapse, 'expanded': expanded  }">
            <div ref="productDescription" class="prod-desc-wrapper">
              <div class="product-long-description" v-html="productDetails.longDescription">
              </div>
            </div>
          </div>
          <a v-if="useCollapse" @click="expanded = !expanded" class="collapse-button theme-primary">
            <div v-if="!expanded" class="read-more">
              <span v-translation="{ type: 'button', code: 'link_description_read_more' }"></span>
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </div>
            <div v-if="expanded" class="read-less">
              <span v-translation="{ type: 'button', code: 'link_description_read_less' }"></span>
              <i class="fa fa-angle-up" aria-hidden="true"></i>
            </div>
          </a>
        </template>        

        <!-- BESPOKE HWS: Grouped specification under a tab and disabled header -->
        <template v-slot:specs>
          <div class="product-specifications">
          <h2 v-translation="{ type: 'title', code: 'title_specifications' }"></h2>
          <div class="spec-list">
            <div class="spec-item" v-for="prop in productProperties" :key="prop.key">
              <div class="spec-desc">
                {{prop.key}}
              </div>
              <div class="spec-value">
                {{prop.value}}
              </div>
            </div>
          </div>
        </div>
        </template>
        <!-- END BESPOKE HWS -->

        <template v-slot:videos v-if="videos.length">
          <div class="product-videos">
            <utlz-product-videos :videos="videos"></utlz-product-videos>
          </div>
        </template>

        <template v-slot:documents v-if="documents.length">
          <div class="product-documents">            
            <utlz-product-documents :documents="documents"></utlz-product-documents>
          </div>
        </template>        

      </utlz-tabs>

      <!-- BESPOKE GOOL: DIsable matrix -->
      <!-- <div v-if="showOrderProductControl && productDetails.prices.length && productVariants.length" class="utlz-col-md-12">
        <utlz-group-matrix :variants="productVariants" />
      </div> -->
      <!-- END BESPOKE GOOL -->      

      <!-- BESPOKE GOOL-45: Switched up and cross selling around -->
      <div v-if="relatedProducts.crossSelling.length" class="related-products upselling utlz-col-12 ">
        <utlz-product-carousel
          :products="relatedProducts.crossSelling"
          :blockSize="relatedProductBlockSize"
          :title="titleCrossSelling"
        ></utlz-product-carousel>
      </div>

      <div v-if="relatedProducts.upSelling.length" class="related-products upselling utlz-col-12 ">
        <utlz-product-carousel
          :products="relatedProducts.upSelling"
          :blockSize="relatedProductBlockSize"
          :title="titleUpSelling"
        ></utlz-product-carousel>
      </div>
      </div>
      <!-- END BESPOKE GOOL-45 -->
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductGroupMatrix from 'src/components/webshop/products/product-group/ProductGroupMatrix.vue';
import ProductGroupDropdown from 'src/components/webshop/products/product-group/ProductGroupDropdowns.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
import Gallery from 'src/components/ui/gallery/Gallery.vue';
import Tabs from 'src/components/ui/tabs/Tabs.vue';
import ProductVideos from 'src/components/webshop/products/product-videos/ProductVideos.vue';
import ProductDocuments from 'src/components/webshop/products/product-documents/ProductDocuments.vue';
import ProductCarousel from 'src/components/webshop/products/product-carousel/ProductCarousel.vue';
// BESPOKE GOOL-67
import PriceOnRequest from './../../../price-on-request/PriceOnRequest.vue';
// END BESPOKE GOOL-7
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-order-product': OrderProduct,
    'utlz-product-stock': ProductStock,
    'utlz-group-matrix': ProductGroupMatrix,
    'utlz-group-dropdown': ProductGroupDropdown,
    'utlz-favorites-control': FavoritesControl,
    'utlz-gallery': Gallery,
    'utlz-tabs': Tabs,
    'utlz-product-carousel': ProductCarousel,
    'utlz-product-videos': ProductVideos,
    'utlz-product-documents': ProductDocuments,
    // BESPOKE GOOL-67
    'utlz-price-on-request': PriceOnRequest
    // END BESPOKE GOOL-67
  },
  data () {
    return {
      expanded: false,
      productDescriptionHeight: 0,
      titleUpSelling: window.vue.translations.title.title_product_relations,
      titleCrossSelling: window.vue.translations.title.title_product_relations_cross,
      tabs: [
        { id: 'description', description: window.vue.translations.label.label_tab_product_desc, active: true, visible: false },
        // BESPOKE GOOL
        // Added product specs as tab
        { id: 'specs', description: window.vue.translations.title.title_specifications, active: false, visible: false },
        // END BESPOKE HWS
        { id: 'documents', description: window.vue.translations.label.label_tab_product_documents, active: false, visible: false },
        { id: 'videos', description: window.vue.translations.label.label_tab_product_videos, active: false, visible: false }
        
      ],
      // BESPOKE
      allPricesRetrieved: false
      // END BESPOKE
    }
  },
  watch: {
    // BESPOKE GOOL
    // Added watcher for specs tab and changed indexed to correspond with positions of tabs in data.tabs array
    longDescription (val) { this.tabs[0].visible = val.length > 0 },
    documents (val) { this.tabs[2].visible = val.length > 0; },
    videos (val) { this.tabs[3].visible = val.length > 0; },    
    productProperties (val) { this.tabs[1].visible = val.length > 0; }
    // END BESPOKE GOOL
  },
  computed: {
    ...mapState('productDetails', ['productDetails', 'productVariants', 'relatedProducts', 'videos']),
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'useOrderLineComments', 'relatedProductBlockSize']),
    ...mapGetters('productDetails', ['documents', 'isUmbrellaProduct']),
    longDescription() {
      return this.productDetails.longDescription;
    },
    useCollapse () {
      // BESPOKE GOOL
      // Changed 150 to 100
      return this.productDescriptionHeight > 100;
      // END BESPOKE GOOL
    },
    showOrderProductControl () {
      if (!this.showPrices) {
        return false;
      } else if (this.showOrderProduct) {
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.productDetails.stock !== null) {
          return this.stockLimit && this.productDetails.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    productProperties () {
      return Object.keys(this.productDetails.properties).map(x => {
        return { key: x, value: this.productDetails.properties[x] }
      });
    },
    images () {
      let images = [];

      images.push(this.productDetails.imageUrl)

      this.productDetails.images.forEach(element => {
        images.push(element);
      });

      return images
    }
  },
  updated () {
    if (this.$refs.productDescription !== undefined) {
      this.productDescriptionHeight = this.$refs.productDescription.clientHeight;
    }
  },
  created () {
    this.initDetails();
  },
  methods: {
    ...mapActions('productDetails', ['initDetails']),
    // BESPOKE VLINT-67
    groupChanged() {
      this.allPricesRetrieved = false;
    },
    retrievedAllPrices() {
      this.allPricesRetrieved = true;
    }
    // END BESPOKE VLINT-67
  }
}
</script>

<style>

</style>
