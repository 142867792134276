export function formatPrice (price, decimals = 2) {
  // BESPOKE GOOL-39
  // Format price thouand notation as 1.000,01 instead of 1000,01
  let priceToFixed =  price.toFixed(decimals)
  return '€ ' + parseFloat(priceToFixed).toLocaleString('nl', {'minimumFractionDigits':2,'maximumFractionDigits':2});
}

// Navigates to vb page, and gives the option to cache current store
export function navigateToHybridUrl (url, { cacheState, moduleKey, stateTree }) {
  if (cacheState) {
    window.sessionStorage[moduleKey] = JSON.stringify(stateTree);
  }
  window.location.href = url;
}

export function retrieveStore (moduleKey) {
  return JSON.parse(window.sessionStorage[moduleKey]);
}
